// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { readLocalStorageValue } from 'common/helpers/readLocalStorageValue';
import { ODBC_DEFAULT_LIST_INPUT } from 'components/pages/OdbcPage/defaultListInput';
import { odbcAddRoute } from 'routes/odbcAddRoute';
import { odbcEditRoute } from 'routes/odbcEditRoute';
import apolloClient from '../apolloClient';

import ODBC_SERVER_LIST from 'queries/OdbcServerList.graphql';

export const odbcServerRoute = {
    path: '/cp/server/odbc',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/OdbcPage/OdbcServerPage" */
        'components/pages/OdbcPage/OdbcServerPage'
    ),
    loader: async () => {
        const input = readLocalStorageValue('odbcServerPageListInput', ODBC_DEFAULT_LIST_INPUT);
        await apolloClient.query({
            query: ODBC_SERVER_LIST,
            variables: { input },
        });
    },
    children: [
        odbcAddRoute,
        odbcEditRoute,
    ],
};
