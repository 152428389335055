// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Dropdown, Menu, MenuItem, Button, Text } from '@plesk/ui-library';
import { Locale } from 'jsw';
import { useMediaQuery } from 'common/hooks';
import Link from 'components/common/Link';

const Translate = Locale.getTranslate('components.subscription-switcher');

const changeIdRe = new RegExp(`(${[
    encodeURIComponent('/domain@'),
    '/domainId/',
    '/domains/',
    '/web/[a-z\-]+/id/(?:d:)?',
    '/ssl-certificate/list/id/',
    '/log-file/browser/id/',
    '/dns-zone/records-list/id/',
    '/mail-settings/edit/id/',
    '/failed-request/list/id/',
    '/asp-dot-net/settings/id/',
    '/iis-app-pool/settings/id/',
].join('|')})(\\d+)`);

const SubscriptionSwitcher = ({ subscriptions, limit, returnUrl, ...props }) => {
    const location = useLocation();
    const isHideText = useMediaQuery('(max-width: 767px)');
    const [showAll, setShowAll] = useState(props.showAll);
    const [currentSubscriptionId, setCurrentSubscriptionId] = useState(props.currentSubscriptionId);

    useEffect(() => {
        setShowAll(props.showAll);
        setCurrentSubscriptionId(props.currentSubscriptionId);
    }, [props.showAll, props.currentSubscriptionId]);

    const title = showAll
        ? <Translate content="allSubscriptions" />
        : subscriptions.find(({ id }) => id === currentSubscriptionId)?.name;

    const button = (
        <Button
            data-type="subscription-switcher"
            data-state={showAll ? 'all-subscriptions' : 'single-subscription'}
            icon="globe"
            tooltip={<Translate content="subscription" />}
            caret={subscriptions.length > 1 && !isHideText}
            tabIndex={subscriptions.length > 1 ? null : -1}
            style={subscriptions.length > 1 ? null : { pointerEvents: 'none' }}
            ghost
        >
            {isHideText ? null : <Text truncate>{title}</Text>}
        </Button>
    );

    if (subscriptions.length === 1) {
        return button;
    }

    return (
        <Dropdown
            data-type="subscription-switcher-dropdown"
            menu={(
                <Menu data-type="subscription-switcher-menu">
                    {subscriptions.length > 1 ? (
                        <MenuItem
                            component={Link}
                            to={subscriptions.length > limit ? null : '/smb/account/switch/all/true'}
                            onClick={subscriptions.length > limit ? null : () => {
                                setShowAll(true);
                            }}
                            disabled={subscriptions.length > limit}
                            tooltip={subscriptions.length > limit ? (
                                <Translate
                                    content="allSubscriptionsLimitOverusedDescription"
                                    params={{
                                        value: subscriptions.length,
                                        limit,
                                    }}
                                />
                            ) : null}
                        >
                            <Translate content="allSubscriptions" />
                        </MenuItem>
                    ) : null}
                    {subscriptions.map(({ id, name }) => (
                        <MenuItem
                            key={id}
                            component={Link}
                            to={`/smb/account/switch/all/false/id/${id}?returnUrl=${encodeURIComponent((returnUrl || location.pathname).replace(changeIdRe, `$1${id}`))}`}
                            onClick={() => {
                                setShowAll(false);
                                setCurrentSubscriptionId(id);
                            }}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Menu>
            )}
            menuPlacement="bottom-end"
        >
            {button}
        </Dropdown>
    );
};

SubscriptionSwitcher.propTypes = {
    subscriptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    limit: PropTypes.number,
    showAll: PropTypes.bool,
    currentSubscriptionId: PropTypes.number,
    returnUrl: PropTypes.string,
};

SubscriptionSwitcher.defaultProps = {
    limit: 100,
    showAll: false,
    currentSubscriptionId: undefined,
    returnUrl: undefined,
};

export default SubscriptionSwitcher;
